<script lang="ts" setup>
import { RouteName } from '@leon-hub/routing-config-names';

import { VImage } from '@components/image';
import { VLoader } from '@components/loader';

import type {
  RegistrationMainStageEmits,
} from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/types';
import { VForm } from 'web/src/components/Form';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import VReCaptchaBadge from 'web/src/modules/captcha/components/VReCaptchaBadge/VReCaptchaBadge.vue';
import registrationFormWidgets from 'web/src/modules/registration/components/RegistrationFormWidgets/commonRegistrationWidgets';
import {
  useMainStage,
} from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/composables/useMainStage';

const emits = defineEmits<RegistrationMainStageEmits>();

const {
  formRef,
  isTermsCheckboxHidden,
  freeBetBannerUrl,
  bannerUrl,
  tabsItems,
  customErrors,
  isFormPending,
  schema,
  uiSchema,
  onSubmit,
  emitInput,
  tabsProperties,
  onTabClick,
  restoreFromSnapshot,
  isLargerPaddingBottom,
  isRegistrationHintMessageEnabled,
  registrationHintMessageLink,
} = useMainStage(emits);
</script>

<template>
  <div v-auto-id="'RegistrationMain'">
    <div
      v-if="freeBetBannerUrl || bannerUrl"
      :class="$style['registration__freebet-banner']"
    >
      <VImage
        :src="freeBetBannerUrl || bannerUrl"
        :class="$style['registration__freebet-banner-img']"
      />
    </div>
    <VTabs
      v-if="tabsItems.length > 1"
      :class="$style['registration__tabs']"
      v-bind="tabsProperties"
      with-gap
      @tab-click="onTabClick"
    />
    <div
      :class="{
        [$style['registration__content']]: true,
        [$style['registration__content--larger-padding-bottom']]: isLargerPaddingBottom,
      }"
    >
      <template v-if="schema && uiSchema">
        <VForm
          ref="formRef"
          :schema="schema"
          :ui-schema="uiSchema"
          :custom-errors="customErrors"
          :is-pending="isFormPending"
          :class="$style['registration__form']"
          @submit="onSubmit"
          @input="emitInput"
          @vue:mounted="restoreFromSnapshot"
        >
          <template
            v-for="(component, slotName) in registrationFormWidgets"
            :key="slotName"
            #[slotName]="{ props, events, ref }"
          >
            <component
              :is="component"
              :ref="ref"
              v-bind="props"
              v-on="events"
            />
          </template>
        </VForm>
        <div
          v-if="isTermsCheckboxHidden"
          :class="$style['registration__disclaimer']"
        >
          <TextSlotPlaceholder
            :text="$t('WEB2_SIMPLE_REGISTRATION_TERMS_ACCEPTION')"
          >
            <template #rules>
              <router-link
                :to="{ name: RouteName.CMS_BETTING_PARTICIPATION_AGREEMENT }"
              >
                <span>{{ $t('WEB2_SIMPLE_REGISTRATION_AGREEDM_RULES') }}</span>
              </router-link>
            </template>
          </TextSlotPlaceholder>
        </div>
      </template>
      <VLoader v-else />
      <VReCaptchaBadge
        :lang="$lang"
      />
      <div
        v-if="isRegistrationHintMessageEnabled"
        :class="$style['registration__hint-message-link']"
      >
        <component
          :is="!!registrationHintMessageLink ? 'router-link' : 'p'"
          :to="registrationHintMessageLink || ''"
        >
          {{ $t('WEB2_GAMBLING_ADDICTIVE_WARNING') }}
        </component>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.registration {
  &__freebet-banner {
    display: block;
    margin: 8px;
    overflow: hidden;
    border-radius: 5px;

    &-img {
      display: block;
      max-width: 100%;
    }

    @include is-app-layout-desktop {
      margin: 8px 24px;
    }
  }

  &__tabs {
    padding: 0 8px;
    margin-bottom: 16px;

    @include is-app-layout-desktop {
      padding: 0 24px;
    }
  }

  &__content {
    padding: 0 16px;

    @include is-app-layout-desktop {
      padding: 0 32px 32px;

      &--larger-padding-bottom {
        padding-bottom: 100px;
      }
    }
  }

  &__form {
    margin-bottom: 20px;
  }

  &__disclaimer {
    @include regular\14\24\025;

    margin-bottom: 20px;
    color: var(--TextPrimary);

    a {
      text-decoration: none;

      span {
        display: inline-block;
        text-decoration: underline;

        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    a:hover {
      color: var(--BrandHighlight);
    }
  }

  &__hint-message-link {
    @include medium\12\16;

    text-align: center;

    p {
      margin: 0;
    }
  }
}
</style>
