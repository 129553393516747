import type { Ref } from 'vue';
import { ref, watch } from 'vue';

import type { VFormRef } from 'web/src/components/Form/components/VForm/types';

export function useWhenRegistrationFormPartiallyFilled(formRef: Ref<undefined | VFormRef>): Ref<boolean> {
  const isFilled = ref(false);
  const stop = watch(formRef, () => {
    const form = formRef.value;
    if (form) {
      form.addEventListener('blur', (event) => {
        const value = event.formData[event.field];
        if (value && typeof value === 'string') {
          stop();
          isFilled.value = true;
        }
      });
    }
  });
  return isFilled;
}
