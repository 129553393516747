import type { Ref } from 'vue';
import {
  computed,
  onBeforeMount,
  ref,
  toRef,
} from 'vue';

import type { FormSchema } from '@leon-hub/form-utils';
import { CustomerRegistrationType, PhoneCodeMethod, RegistrationStateExceptionCode } from '@leon-hub/api-sdk';
import { FormControlType } from '@leon-hub/form-utils';

import { useErrorsConverter } from '@core/errors';
import { useI18n } from '@core/i18n';
import { useSiteConfigStore } from '@core/site-config';

import type { FormOutput, FormUiSchema } from 'web/src/components/Form';
import type { FormExternalErrors } from 'web/src/components/Form/types';
import type {
  RegistrationPhoneCheckStageEmits,
} from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/types';
import {
  CustomCustomerFieldType,
  RegistrationFieldName,
  RegistrationFormStep,
  RegistrationStage,
} from 'web/src/modules/registration/enums';
import { useSimplifiedRegistrationStore } from 'web/src/modules/registration/submodules/simplified-registration/store';

interface RegistrationPhoneCheckStageComposable {
  schema: Ref<FormSchema>;
  uiSchema: Ref<FormUiSchema>;
  isFormPending: Ref<boolean>;
  submittedStarredPhone: Ref<string>;
  resendSmsTimeOut: Ref<number>;
  smsResendCodeTimer: Ref<number>;
  customErrors: Ref<FormExternalErrors>;
  isPhoneCheckByCall: Ref<boolean>;
  isShowHintBlockVisible: Ref<boolean>;
  emitCheckSmsCode(data: FormOutput): void;
  resendPhoneCodeByType(): Promise<void>;
  suggestedEmailRegistrationTimeout: Ref<number>;
  setRetrySmsCodeTime(): void;
  changeShowHintBlock(value: boolean): void;
  emitInput(payload: FormOutput): void;
  goToChangePhone(): void;
  goToEmailRegistration(): void;
  goToPhoneCheckBySmsStage(): void;
}

export function usePhoneCheckStage(emits: RegistrationPhoneCheckStageEmits): RegistrationPhoneCheckStageComposable {
  const siteConfigStore = useSiteConfigStore();
  const simpleRegStore = useSimplifiedRegistrationStore();

  const { $translate } = useI18n();

  const phoneCodeLengthSettings = toRef(siteConfigStore, 'phoneCodeLengthSettings');
  const suggestedEmailRegistrationTimeout = toRef(() => siteConfigStore.suggestedEmailRegistrationTimeout);
  const smsResendCodeTimer = toRef(() => siteConfigStore.smsResendCodeTimer);
  const isSuggestedEmailRegistrationEnabled = toRef(siteConfigStore, 'isSuggestedEmailRegistrationEnabled');
  const phoneCheckType = toRef(simpleRegStore, 'phoneCheckType');
  const configuredCaptchaUiSchema = toRef(() => simpleRegStore.configuredCaptchaUiSchema);
  const isFormPending = toRef(simpleRegStore, 'isFormPending');
  const submittedStarredPhone = toRef(simpleRegStore, 'submittedPhoneStarred');
  const resendSmsTimeOut = toRef(() => simpleRegStore.resendSmsTimeOut);
  const customErrors = toRef(simpleRegStore, 'regFormCustomErrors');
  const prevFormData = toRef(simpleRegStore, 'prevFormData');
  const registrationType = toRef(simpleRegStore, 'registrationType');
  const currentFormProperties = toRef(simpleRegStore, 'currentFormProperties');
  const errorConverter = useErrorsConverter();

  const showHintBlock = ref(false);

  const phoneCheckConfirmationCodeLength = computed(() => phoneCodeLengthSettings.value[phoneCheckType.value] || 6);

  onBeforeMount(() => {
    simpleRegStore.isTimerRestarted();
  });

  const schema = computed<FormSchema>(() => {
    const codeLength = phoneCheckConfirmationCodeLength.value;
    return {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      title: 'phoneCheck',
      required: [RegistrationFieldName.SMS_CODE],
      properties: {
        [RegistrationFieldName.SMS_CODE]: {
          type: 'string',
          pattern: `^[0-9]{0,${codeLength}}$`,
          minLength: codeLength,
          maxLength: codeLength,
        },
        [CustomCustomerFieldType.CAPTCHA_TOKEN]: { type: 'string' },
        [CustomCustomerFieldType.CAPTCHA_TYPE]: { type: 'string' },
      },
    };
  });

  const uiSchema = computed<FormUiSchema>(() => ({
    order: [
      RegistrationFieldName.SMS_CODE,
      CustomCustomerFieldType.CAPTCHA_TOKEN,
      CustomCustomerFieldType.CAPTCHA_TYPE,
    ],
    submitButton: {
      label: $translate('WEB2_REGISTRATION_PHONE_CHECK_NEXT').value,
    },
    fields: {
      [RegistrationFieldName.SMS_CODE]: {
        title: $translate('WEB2_SMS_CODE_FIELD_TITLE').value,
        widget: FormControlType.SmsCode,
        options: {
          size: phoneCheckConfirmationCodeLength.value,
          autofocus: true,
        },
      },
      ...configuredCaptchaUiSchema.value,
    },
  }));

  const isPhoneCheckByCall = computed(() => phoneCheckType.value === PhoneCodeMethod.CALL_PASSWORD_CODE);

  function emitCheckSmsCode(data: FormOutput): void {
    emits('check-sms-code', data);
  }

  function setRetrySmsCodeTime(): void {
    simpleRegStore.restartResendSmsTimeout();
  }

  function changeShowHintBlock(value: boolean): void {
    showHintBlock.value = value;
  }

  const isShowHintBlockVisible = computed(() => isSuggestedEmailRegistrationEnabled.value && showHintBlock.value);

  function emitInput(payload: FormOutput) {
    emits('input', payload);
  }

  function goToChangePhone(): void {
    simpleRegStore.goToStage({ stage: RegistrationStage.ChangePhone });
  }

  function goToEmailRegistration(): void {
    simpleRegStore.setCurrentRegistrationType(CustomerRegistrationType.SIMPLE_EMAIL);
    simpleRegStore.goToStage({ stage: RegistrationStage.Main });
  }

  function goToPhoneCheckBySmsStage(): void {
    simpleRegStore.setCurrentRegistrationType(CustomerRegistrationType.SIMPLE_PHONE);
    simpleRegStore.goToStage({ stage: RegistrationStage.Main });
  }

  async function resendPhoneCodeByType(): Promise<void> {
    if (simpleRegStore.isTimerRestarted()) {
      const form: FormOutput = {
        formData: {
          ...prevFormData.value,
          code: '',
          step: RegistrationFormStep.Initial,
        },
        errors: null,
        customErrors: null,
        field: '',
      };
      if (registrationType.value) {
        try {
          await simpleRegStore.onResendSmsCode(form, {
            registrationType: registrationType.value,
            schemaId: currentFormProperties.value?.schemaId ?? '',
          }, phoneCheckType.value);
        } catch (rawError) {
          const error = errorConverter.convertToBaseError(rawError);
          if (error.code.equals(RegistrationStateExceptionCode.REGISTRATION_STATE_ERROR)) {
            await simpleRegStore.resetRegistrationState();
            simpleRegStore.goToStage({ stage: RegistrationStage.Main, clearHistory: true });
          }
        }
      }
    }
  }

  return {
    schema,
    uiSchema,
    isFormPending,
    submittedStarredPhone,
    resendSmsTimeOut,
    smsResendCodeTimer,
    customErrors,
    suggestedEmailRegistrationTimeout,
    isPhoneCheckByCall,
    emitCheckSmsCode,
    setRetrySmsCodeTime,
    isShowHintBlockVisible,
    changeShowHintBlock,
    emitInput,
    goToChangePhone,
    goToEmailRegistration,
    goToPhoneCheckBySmsStage,
    resendPhoneCodeByType,
  };
}
