<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';
import { Tag } from '@leon-hub/tags';

import { ButtonKind, VButton } from '@components/buttons';

import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import VTooltip from 'web/src/modules/dialogs/components/VTooltip/VTooltip.vue';
import CopyPassword
  from 'web/src/modules/registration/submodules/simplified-registration/components/CopyPassword/CopyPassword.vue';
import {
  useSuccessStage,
} from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/composables/useSuccessStage';

const {
  userPassword,
  onCopyPasswordClick,
  goToChangePassword,
  goToDeposit,
  isTooltipShown,
} = useSuccessStage();
</script>

<template>
  <div v-auto-id="'RegistrationSuccess'" :class="$style['registration-success']">
    <VJumbotron
      :icon="IconName.CHECK_OUTLINE"
      :icon-size="IconSize.SIZE_80"
      :heading="$t('WEB2_YOUR_PASSWORD')"
      :class="$style['registration-success__jumbotron']"
    >
      <div :class="$style['registration-success__congrats']">
        {{ $t('WEB2_REGISTRATION_SUCCESS_DESCR') }}
      </div>
      <CopyPassword
        :user-password="userPassword || ''"
        @click="onCopyPasswordClick"
        @keydown.enter.space="onCopyPasswordClick"
      />
      <VButton
        :tag="Tag.A"
        :label="$t('WEB2_SETT_PASSCHANGE')"
        :kind="ButtonKind.TRANSPARENT"
        tabindex="0"
        :class="$style['registration-success__change-password']"
        @click="goToChangePassword"
        @keydown.enter.space="goToChangePassword"
      />
      <template #footer>
        <slot>
          <JumbotronFooterButton
            :label="$t('WEB2_DEPOSIT_FUNDS')"
            :kind="ButtonKind.PRIMARY"
            full-width
            :icon-name="IconName.ADD"
            @click="goToDeposit"
          />
        </slot>
      </template>
      <VTooltip
        v-if="isTooltipShown"
        :class="$style['registration-success__tooltip']"
        :text="$t('WEB2_PASSWORD_HAS_BEEN_COPIED')"
      />
    </VJumbotron>
  </div>
</template>

<style lang="scss" module>
.registration-success {
  padding: 0 16px;
  margin-top: 16px;

  &__jumbotron {
    color: var(--BrandDefault);
  }

  &__change-password {
    @include regular\12\16;

    height: auto;
    padding: 4px 0 10px;
    color: var(--TextSecondary);
    text-transform: lowercase;
    text-decoration: underline;

    &:focus {
      color: var(--BrandHighlight);
      outline: none;
    }
  }

  &__congrats {
    @include regular\14\24\025;

    color: var(--TextPrimary);
  }

  &__tooltip {
    top: calc(50% - #{$titleBarHeight});
  }

  @include is-app-layout-desktop {
    padding: 0 32px 32px;
  }
}
</style>
