import type { Ref } from 'vue';
import { watch } from 'vue';

import type { UseApiMethod } from '@leon-hub/api-types';
import { useDoRegistration } from '@leon-hub/api-sdk';
import { logger } from '@leon-hub/logging';

import { useGraphqlClient } from '@core/app-rest-client';

import { useShieldIntegration } from 'web/src/modules/shield-integration';

export function useRegistrationHook(canProfile: Ref<boolean>): UseApiMethod {
  if (process.env.VUE_APP_FEATURE_SHIELD_INTEGRATION_ENABLED) {
    const { load, sessionId, helmetId } = useShieldIntegration();
    function run(): void {
      void load().catch((exception: unknown) => console.error(exception));
    }
    if (canProfile.value) {
      run();
    } else {
      watch(canProfile, run, {
        once: true,
      });
    }

    // Subscribe on registration request to add variables.
    return useDoRegistration(useGraphqlClient(), (context) => {
      const { simpleRegistration } = context.variables.options;
      // TODO: remove after prod debug
      logger.warn(`[registration] simpleRegistration=${simpleRegistration}; helmetDeviceId: ${sessionId.value};`);
      if (simpleRegistration) {
        return {
          ...context,
          variables: {
            ...context.variables,
            options: {
              ...context.variables.options,
              simpleRegistration: {
                ...simpleRegistration,
                helmetDeviceId: sessionId.value,
                helmetId: helmetId.value,
              },
            },
          },
        };
      }
      return context;
    });
  }
  return { stop() {} };
}
