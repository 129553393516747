import type { Ref } from 'vue';
import { computed, ref, toRef } from 'vue';

import { FormControlType } from '@leon-hub/form-utils';
import { assert } from '@leon-hub/guards';
import { RouteName } from '@leon-hub/routing-config-names';

import { PresetName, useDialogs } from '@core/dialogs';
import { useErrorsConverter } from '@core/errors';
import { useI18n } from '@core/i18n';

import type { FormOutput, FormUiSchema } from 'web/src/components/Form';
import type { VFormRef } from 'web/src/components/Form/components/VForm/types';
import type {
  RegistrationChangePasswordStageEmits,
} from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/types';
import { RegistrationStage } from 'web/src/modules/registration/enums';
import isChangePasswordFormData
  from 'web/src/modules/registration/submodules/simplified-registration/guards/isChangePasswordFormData';
import { useSimplifiedRegistrationStore } from 'web/src/modules/registration/submodules/simplified-registration/store';
import { useChangePassword } from 'web/src/modules/user/composables/useChangePassword';

interface ChangePasswordComposables {
  isSuccess: Ref<boolean>;
  uiSchema: Ref<FormUiSchema>;
  isFormPending: Ref<boolean>;
  goToDeposits(): void;
  onChangePasswordSubmit(data: FormOutput): Promise<void>;
  emitOnInput(payload: FormOutput): void;
  passwordChangeForm: Ref<VFormRef | undefined>;
}

export function useChangePasswordStage(emits: RegistrationChangePasswordStageEmits): ChangePasswordComposables {
  const simpleRegStore = useSimplifiedRegistrationStore();
  const { changePassword } = useChangePassword();
  const errorConverter = useErrorsConverter();
  const { $translate } = useI18n();

  const passwordChangeForm = ref<VFormRef>();
  const stage = toRef(() => simpleRegStore.stage);
  const oldPassword = toRef(() => simpleRegStore.generatedPassword);
  const isFormPending = ref(false);
  const { showDialog } = useDialogs();

  const isSuccess = computed(() => stage.value === RegistrationStage.ChangePasswordSuccess);

  function goToDeposits(): void {
    simpleRegStore.goToRouteName(RouteName.DEPOSITS);
  }

  const uiSchema = computed<FormUiSchema>(() => ({
    order: [
      'newPassword',
      'oldPassword',
    ],
    fields: {
      newPassword: {
        widget: FormControlType.PasswordValidator,
        title: $translate('WEB2_PASSWORD_INPUT_LABEL').value,
        options: {
          placeholder: $translate('WEB2_PASSWORD_INPUT_PLACEHOLDER').value,
        },
      },
      oldPassword: {
        hidden: true,
        widget: FormControlType.Hidden,
        default: oldPassword.value || undefined,
      },
    },
    submitButton: {
      label: $translate('WEB2_REGISTRATION_CHANGE_PASSWORD').value,
      disabled: false,
    },
  }));

  async function onChangePasswordSubmit(data: FormOutput): Promise<void> {
    assert(isChangePasswordFormData(data.formData));
    try {
      isFormPending.value = true;
      await changePassword({
        oldPassword: encodeURIComponent(data.formData.oldPassword),
        newPassword: encodeURIComponent(data.formData.newPassword),
      });
      simpleRegStore.goToStage({ stage: RegistrationStage.ChangePasswordSuccess });
    } catch (rawError) {
      const error = errorConverter.convertToBaseError(rawError);
      simpleRegStore.setRegistrationError(error.message);

      void showDialog({
        presetName: PresetName.ALERT_WARNING,
        options: {
          title: $translate('JS_CAPTION_ATTENTION').value,
          confirmMessage: error.message,
        },
      }).promise.then(() => {
        passwordChangeForm.value?.reset();
      });
    } finally {
      isFormPending.value = false;
    }
  }

  function emitOnInput(payload: FormOutput): void {
    emits('input', payload);
  }

  return {
    isSuccess,
    uiSchema,
    isFormPending,
    goToDeposits,
    onChangePasswordSubmit,
    emitOnInput,
    passwordChangeForm,
  };
}
