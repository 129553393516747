<script lang="ts" setup>
import { IconName, IconSize } from '@leon-hub/icons';

import { ButtonKind } from '@components/buttons';

import type {

  FormSchema,
} from 'web/src/components/Form/types';
import type {
  RegistrationChangePasswordSlots,
  RegistrationChangePasswordStageEmits,
} from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/types';
import { VForm } from 'web/src/components/Form';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import {
  useChangePasswordStage,
} from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/composables/useChangePasswordStage';

const emits = defineEmits<RegistrationChangePasswordStageEmits>();

defineSlots<RegistrationChangePasswordSlots>();
const schema: FormSchema = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  title: 'registrationPasswordChange',
  required: ['oldPassword', 'newPassword'],
  properties: {
    oldPassword: {
      type: 'string',
    },
    newPassword: {
      type: 'string',
      minLength: 8,
      pattern: '^(?=.*[a-zA-Z])(?=.*[0-9]).*$',
    },
  },
};
const {
  isSuccess,
  goToDeposits,
  uiSchema,
  isFormPending,
  onChangePasswordSubmit,
  emitOnInput,
  passwordChangeForm,
} = useChangePasswordStage(emits);
</script>

<template>
  <div v-auto-id="'RegistrationChangePassword'" :class="$style['change-password-success']">
    <VJumbotron
      v-if="isSuccess"
      :icon="IconName.CHECK_OUTLINE"
      :icon-size="IconSize.SIZE_80"
      :heading="$t('WEB2_PASSWORD_HAS_CHANGED')"
      :class="$style['change-password-success__jumbotron']"
    >
      {{ $t('WEB2_REGISTRATION_SUCCESS_DESCR') }}
      <template #footer>
        <slot>
          <JumbotronFooterButton
            :label="$t('WEB2_DEPOSIT_FUNDS')"
            full-width
            :kind="ButtonKind.PRIMARY"
            :icon-name="IconName.ADD"
            @click="goToDeposits"
          />
        </slot>
      </template>
    </VJumbotron>
    <template v-else>
      <VForm
        ref="passwordChangeForm"
        :class="$style['change-password-success__form']"
        :schema="schema"
        :ui-schema="uiSchema"
        :is-pending="isFormPending"
        @submit="onChangePasswordSubmit"
        @input="emitOnInput"
      />
    </template>
  </div>
</template>

<style lang="scss" module>
@include for-layout using($isDesktop) {
  .change-password-success {
    padding: if($isDesktop, 0 32px 32px, 0 16px);

    > .change-password-success__form {
      margin-top: 16px;
    }

    &__jumbotron {
      color: var(--BrandDefault);
    }
  }
}
</style>
