<script lang="ts" setup>

import { computed } from 'vue';

import type {
  SimpleRegistrationRouteComponentProps,
} from 'web/src/modules/registration/submodules/simplified-registration/types';
import { VLoaderDelayed } from 'web/src/components/Loader';
import RegistrationChangePhoneNumber
  from 'web/src/modules/registration/components/RegistrationChangePhoneNumber/RegistrationChangePhoneNumber.vue';
import {
  RegistrationStage,
} from 'web/src/modules/registration/enums';
import RegistrationChangePassword
  from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/RegistrationChangePassword.vue';
import RegistrationMain
  from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/RegistrationMain.vue';
import RegistrationPhoneCheck
  from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/RegistrationPhoneCheck.vue';
import RegistrationSuccess
  from 'web/src/modules/registration/submodules/simplified-registration/components/Stages/RegistrationSuccess.vue';
import {
  useSimpleRegistrationRoute,
} from 'web/src/modules/registration/submodules/simplified-registration/composables/useSimpleRegistrationRoute';

const props = defineProps<SimpleRegistrationRouteComponentProps>();

const {
  isFormLoaded,
  registrationStage,
  onInput,
  onSubmitWrapped,
  onChangePhone,
  onCheckSmsCode,
} = useSimpleRegistrationRoute(props);

const stageComponent = computed(() => {
  switch (registrationStage.value) {
    case RegistrationStage.ChangePhone:
      return RegistrationChangePhoneNumber;
    case RegistrationStage.PhoneCheckByCall:
    case RegistrationStage.PhoneCheck:
      return RegistrationPhoneCheck;
    case RegistrationStage.ChangePasswordSuccess:
    case RegistrationStage.ChangePassword:
      return RegistrationChangePassword;
    case RegistrationStage.Success:
      return RegistrationSuccess;
    case RegistrationStage.Main:
      return RegistrationMain;
    default: {
      return RegistrationMain;
    }
  }
});
</script>

<template>
  <div v-auto-id="'SimpleRegistrationRouteComponent'">
    <VLoaderDelayed v-if="!isFormLoaded" />
    <template v-else>
      <component
        :is="stageComponent"
        @input="onInput"
        @submit="onSubmitWrapped"
        @change-phone="onChangePhone"
        @check-sms-code="onCheckSmsCode"
      />
    </template>
  </div>
</template>
